<template>
  <div id="app">
    <!-- <div class="no_page">
      <img src="@assets/no_page.png"/>
      网站升级中......预计将于9月20日可正常浏览
    </div> -->
    <router-view />
  </div>
</template>

<script>
import browser from '@/src/mixins/browser'
export default {
  name: 'APP',
  mixins: [browser],
  mounted() {
    if (this.isMobile) {
      const { protocol, host } = window.location
      window.location.href = protocol + '//' + host + '/mobile.html#/'
    }
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  font-family: SourceHanSansCN, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  height: 100%;
}

.no_page {
  height: 100%;
  font-size: 16px;
  color: #C7C7C7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >img {
    margin-bottom: 80px;
    width: 270px;
    height: auto;
  }
}

.long_text_class {
  max-width: 360px;
  line-height: 26px;
  text-indent: 24px;
  word-break: break-all;
  word-wrap: break-word;
  text-align: justify;
}

// element ui 样式修改
.search-box .el-input__prefix {
  left: 12px;
}

.search-box .el-input__icon {
  font-size: 18px !important;
}

.search-box .el-input--prefix .el-input__inner {
  padding-left: 40px !important;
}

.search-box .el-input__inner {
  font-size: 14px !important;
  color: #656565 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #FA3E3E !important;
  border-color: #FA3E3E !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #222 !important;
}

.el-checkbox {
  color: #222 !important;
}

.el-checkbox__label {
  padding-left: 6px !important;
}

.el-checkbox__inner {
  width: 16px !important;
  height: 16px !important;
  border-radius: 3px !important;
  border: 1px solid #D6D6D6 !important;
}

.el-checkbox__inner::after {
  left: 5px !important;
  top: 2px !important;
}

.search-box .el-input--small .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}

.search-box .el-input--small .el-input__icon {
  line-height: 28px !important;
}

.el-input {
  width: 180px !important;
}

.table-header-cell,
.table-data th,
.table_single th {
  color: rgba(34, 34, 34, 0.8) !important;
  font-weight: 400 !important;
  background-color: #F1F2F3 !important;
}

.table_history_box {
  .table_head {
    color: rgba(34, 34, 34, 0.8) !important;
    font-weight: 400 !important;
    background-color: #F1F2F3 !important;
  }
}

.header .title-box .title {
  font-weight: bold !important;
}

a {
  text-decoration: none !important;
}

.el-table .cell {
  white-space: nowrap !important;
  padding: 0 0.4rem !important;
}
</style>
