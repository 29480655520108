import Vue from 'vue'
import { Loading } from 'element-ui'

let loadingCount = 0
let loading
const startLoading = (msg) => {
  loading = Loading.service({
    fullscreen: true,
    lock: true,
    text: msg || '拼命加载中',
    background: 'rgba(0, 0, 0, 0)',
    customClass: 'nn-loading'
  })
}

const endLoading = () => {
  loading.close()
}

const showLoading = (msg) => {
  if (loadingCount === 0) {
    startLoading(msg)
  } else {
    loading.text = msg || '拼命加载中'
  }
  loadingCount += 1
}

const hideLoading = () => {
  if (loadingCount <= 0) {
    return
  }
  loadingCount -= 1
  if (loadingCount === 0) {
    endLoading()
  }
}

const LoadingPlugin = {
  install: (Vue, options) => {
    Vue.prototype.$loading = {
      show: showLoading,
      hide: hideLoading
    }
  }
}

Vue.use(LoadingPlugin)

export default LoadingPlugin
