import instance from './instance'

const api = {
  home: () => {
    return instance.get('api/home')
  },
  index: () => {
    return instance.get('api/index')
  },
  allTicket: () => {
    return instance.get('api/all_ticket')
  },
  historyOpen: (code, per_page) => {
    return instance.get(`api/history_open/${code}`, {
      params: {
        per_page: per_page
      }
    })
  },
  historyStatistics: (code, type) => {
    return instance.get(`api/history_statistics/${code}`, {
      params: { type }
    })
  },
  hotCold: (code, count) => {
    return instance.get(`api/hot_cold/${code}`, {
      params: {
        per_page: count
      }
    })
  },
  missing: (code, type, location, per_page) => {
    return instance.get(`api/missing/${code}`, {
      params: { type, location, per_page }
    })
  },
  todayNumber: (code) => {
    return instance.get(`api/today_number/${code}`)
  },
  specialCode: (code, day) => {
    return instance.get(`api/special_code/${code}`, {
      params: { day }
    })
  },
  longDragon: (code) => {
    return instance.get(`/api/long_dragon/${code}`)
  },
  roadBead: (code, per_page, type) => {
    return instance.get(`api/road_bead/${code}`, {
      params: { per_page, type }
    })
  },
  detail: (code, id) => {
    return instance.get(`api/detail/${code}/${id}`)
  },
  numberTrend: (code, page) => {
    return instance.get(`api/number_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  bigSmallTrend: (code, page) => {
    return instance.get(`api/big_small_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  singleDoubleTrend: (code, page) => {
    return instance.get(`api/single_double_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  loongTigerTrend: (code, page) => {
    return instance.get(`api/loong_tiger_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  beforeAfterTrend: (code, page) => {
    return instance.get(`api/before_after_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  combinationTrend: (code, page) => {
    return instance.get(`api/combination_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  positionTrend: (code, page) => {
    return instance.get(`api/position_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  followTrend: (code, page) => {
    return instance.get(`api/follow_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  live: (code, id) => {
    return instance.get(`api/live/${code}/${id}`)
  },
  article: (id, page, pageSize) => {
    return instance.get('api/article', {
      params: {
        page,
        per_page: pageSize,
        cate_id: id
      }
    })
  },
  articleDetail: (id) => {
    return instance.get(`api/article_detail/${id}`)
  },
  cooperation: (data) => {
    return instance.post('api/cooperation', data)
  },
  allCategory: (cate) => {
    return instance.get(`api/all_category/${cate}`)
  },
  allTicketCate: () => {
    return instance.get('api/all_ticket_cate')
  },
  plan: (code, date, page) => {
    return instance.get(`api/plan/${code}`, {
      params: {
        date,
        per_page: page
      }
    })
  },
  kill: (code, date, page) => {
    return instance.get(`api/kill/${code}`, {
      params: {
        date,
        per_page: page
      }
    })
  },
  formula: (code) => {
    return instance.get(`api/formula/${code}`)
  },
  zodiacTrend: (code, type, page) => {
    return instance.get(`api/zodiac_trend/${code}`, {
      params: {
        type,
        per_page: page
      }
    })
  },
  sumTrend: (code, page) => {
    return instance.get(`api/sum_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  patternTrend: (code, page) => {
    return instance.get(`api/pattern_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  decorTrend: (code, page) => {
    return instance.get(`api/decor_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  tailTrend: (code, page) => {
    return instance.get(`api/tail_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  boseTrend: (code, page) => {
    return instance.get(`api/bose_trend/${code}`, {
      params: {
        per_page: page
      }
    })
  },
  chart: (code, type, per_page) => {
    return instance.get(`api/chart/${code}`, {
      params: {
        type,
        per_page
      }
    })
  }
}

export default api
