export const fn = (code) => {
    if (code === 'xyft') {
        return '原名lucky airship,是一种起源于水上赛艇，由马耳他瓦莱塔福利联合委员会监制而发行的彩票'
    }
    if (code === 'donggansaiche') {
        return 'Blockchain lottery区块链彩票，PAGCOR、立博、澳娱联合开发，由PAGCOR旗下博彩公司销售的网络彩票'
    }
    if (code === 'jisupk10') {
        return '引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控，联合开发的彩票游戏'
    }
    if (code === 'azxy10') {
        return '始于2013年的南澳大利亚州新彩票，由tatts集团旗下Tatts Group经营的政府监管官方彩票'
    }
    if (code === 'jisupk10168') {
        return '引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控，联合开发的彩票游戏'
    }
    if (code === 'azxy10168') {
        return '始于2013年的南澳大利亚州新彩票，由tatts集团旗下Tatts Group经营的政府监管官方彩票'
    }
    if (code === 'azxy8') {
        return '始于2013年的南澳大利亚州新彩票，由tatts集团旗下Tatts Group经营的政府监管官方彩票'
    }
    if (code === 'jisukl10') {
        return '引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控，联合开发的彩票游戏'
    }
    if (code === 'azxy8168') {
        return '始于2013年的南澳大利亚州新彩票，由tatts集团旗下Tatts Group经营的政府监管官方彩票'
    }
    if (code === 'jisukl10168') {
        return '引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控，联合开发的彩票游戏'
    }
    if (code === 'gdklsf') {
        return 'Blockchain lottery区块链彩票，PAGCOR、立博、澳娱联合开发，由PAGCOR旗下博彩公司销售的网络彩票'
    }
    if (code === 'donggan61') {
        return 'Blockchain lottery区块链彩票，PAGCOR、立博、澳娱联合开发，由PAGCOR旗下博彩公司销售的网络彩票'
    }
    if (code === 'amlhc') {
        return '由澳门博彩监察协调局监制，澳门博彩控股有限公司独家开发、销售的互联网博彩彩票'
    }
    if (code === 'twlhc') {
        return '由台湾两大博彩类游戏公司Gamesofa和IGS联合发行与销售的网络彩'
    }
    if (code === 'hk6') {
        return '源于1975年的香港马票，由香港政府批准，香港赛马会独家发行销售的公益型彩票'
    }
    if (code === 'dongganshengxiao') {
        return 'Blockchain lottery区块链彩票，PAGCOR、立博、澳娱联合开发，由PAGCOR旗下博彩公司销售的网络彩票'
    }
    if (code === 'azxy5') {
        return '始于2013年的南澳大利亚州新彩票，由tatts集团旗下Tatts Group经营的政府监管官方彩票'
    }
    if (code === 'azxy5168') {
        return '始于2013年的南澳大利亚州新彩票，由tatts集团旗下Tatts Group经营的政府监管官方彩票'
    }
    if (code === 'txffc') {
        return '开奖源接口合作方以腾讯“在线用户人数”后四位及该四位相加后的尾数为万位，转换为腾讯分分彩赛果'
    }
    if (code === 'jisussc') {
        return '引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控，联合开发的彩票游戏'
    }
    if (code === 'jisussc168') {
        return '引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控，联合开发的彩票游戏'
    }
    if (code === 'dongganpuke') {
        return 'Blockchain lottery区块链彩票，PAGCOR、立博、澳娱联合开发，由PAGCOR旗下博彩公司销售的网络彩票'
    }
    if (code === 'donggansanbao' || code === 'speedy3' || code === 'sgquick3') {
        return 'Blockchain lottery区块链彩票，PAGCOR、立博、澳娱联合开发，由PAGCOR旗下博彩公司销售的网络彩票'
    }
    if (code === 'pcdd') {
        return '根据中国福利彩票 “快乐8“结果，按大小顺序排列的1～6位和值尾数、7～12位和值尾数、13～18位和值尾数分别作为PC蛋蛋的第一球、第二球、第三球'
    }
    return '暂无简介'
}

export const get_type_name = (code, other, is_special) => {
    if (!code) return []
    const cz_obj = {
        'xyft': ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名'],
        'donggansaiche': ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名'],
        '168xyft': ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名'],
        'jisupk10': ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名'],
        'azxy10': ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名'],
        'jisupk10168': ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名'],
        'azxy10168': ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名'],
        'sbspeedy10': ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名'],
        'sgairship': ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名'],

        'azxy8': ['第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球'],
        'jisukl10': ['第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球'],
        'azxy8168': ['第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球'],
        'jisukl10168': ['第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球'],
        'gdklsf': ['第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球'],

        'donggan61': ['正一', '正二', '正三', '正四', '正五', '正六', '特码'],
        'amlhc':  ['正一', '正二', '正三', '正四', '正五', '正六', '特码'],
        'twlhc': ['正一', '正二', '正三', '正四', '正五', '正六', '特码'],
        'hk6':  ['正一', '正二', '正三', '正四', '正五', '正六', '特码'],
        'happy61': ['正一', '正二', '正三', '正四', '正五', '正六', '特码'],

        'dongganshengxiao': ['第一名', '第二名', '第三名', '第四名', '第五名', '第六名'],

        'azxy5':  ['第一球', '第二球', '第三球', '第四球', '第五球'],
        'azxy5168':  ['第一球', '第二球', '第三球', '第四球', '第五球'],
        'txffc':  ['第一球', '第二球', '第三球', '第四球', '第五球'],
        'jisussc':  ['第一球', '第二球', '第三球', '第四球', '第五球'],
        'jisussc168':  ['第一球', '第二球', '第三球', '第四球', '第五球'],
        'dongganwuxing':  ['第一球', '第二球', '第三球', '第四球', '第五球'],
        'sg5d': ['第一球', '第二球', '第三球', '第四球', '第五球'],

        'dongganpuke': ['第一张', '第二张', '第三张', '第四张'],

        'donggansanbao': ['总和'],
        'speedy3': ['总和'],
        'sgquick3': ['总和'],

        'pcdd': ['第一球', '第二球', '第三球']
    }
    let arr = cz_obj[code] ? cz_obj[code] : []
    if (is_special === 10) {
        if (arr.length === 10) {
            arr = arr.concat(['冠亚和', '三四和', '五六和', '七八和', '九十和'])
        }
    }
    const type = Object.prototype.toString.call(other)
    if (type === '[object Array]') {
        return arr.concat(other)
    } else if (type === '[object String]') {
        arr.push(other)
        return arr
    } else {
        return arr
    }
}