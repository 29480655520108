import api from '@/src/api/api.js'
import store from './index'

export default {
  namespaced: true,
  state: {
    data: null
  },
  getters: {
    list (state, getters, rootState) {
      if (!state.data) {
        store.dispatch('lottery/getData')
      }
      return state.data
    }
  },
  mutations: {
    setData (state, data) {
      state.data = data
    }
  },
  actions: {
    async getData ({ state, commit, rootState }) {
      const res = await api.allTicketCate()
      commit('setData', res.data)
    }
  }
}
