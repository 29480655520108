import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@views/pc/Home')
  },
  {
    path: '/lottery',
    name: 'Lottery',
    component: () => import('@views/pc/lottery/Index'),
    children: [
      {
        path: '',
        component: () => import('@views/pc/lottery/Home')
      },
      {
        path: 'data',
        name: 'Data',
        component: () => import('@views/pc/lottery/Data'),
        props: { category: 'data' },
        children: [
          {
            path: 'history/:code/:id',
            component: () => import('@views/pc/lottery/LotteryHistory'),
            props: true
          },
          {
            path: 'statistics/:code/:id',
            component: () => import('@views/pc/lottery/LotteryStatistics'),
            props: true
          },
          {
            path: 'analyse/:code/:id',
            component: () => import('@views/pc/lottery/LotteryAnalyse'),
            props: true
          },
          {
            path: 'omit/:code/:id',
            component: () => import('@views/pc/lottery/LotteryOmit'),
            props: true
          },
          {
            path: 'today/:code/:id',
            component: () => import('@views/pc/lottery/LotteryToday'),
            props: true
          },
          {
            path: 'tema/:code/:id',
            component: () => import('@views/pc/lottery/LotteryTema'),
            props: true
          },
          {
            path: 'long/:code/:id',
            component: () => import('@views/pc/lottery/LotteryLong'),
            props: true
          },
          {
            path: 'map/:code/:id',
            component: () => import('@views/pc/lottery/LotteryMap'),
            props: true
          }
        ]
      },
      {
        path: 'lz',
        name: 'LZ',
        component: () => import('@views/pc/lottery/Data'),
        props: { category: 'road_bead' },
        children: [
          {
            path: ':type/:code/:id',
            component: () => import('@views/pc/lottery/lz/LZNumber'),
            props: true
          }
        ]
      },
      {
        path: 'trend',
        name: 'Trend',
        component: () => import('@views/pc/lottery/Data'),
        props: { category: 'trend' },
        children: [
          {
            path: 'number/:code/:id',
            component: () => import('@views/pc/lottery/trend/Number'),
            props: true
          },
          {
            path: 'size/:code/:id',
            component: () => import('@views/pc/lottery/trend/BigSmall'),
            props: true
          },
          {
            path: 'single-double/:code/:id',
            component: () => import('@views/pc/lottery/trend/SingleDouble'),
            props: true
          },
          {
            path: 'dragon-tiger/:code/:id',
            component: () => import('@views/pc/lottery/trend/DragonTiger'),
            props: true
          },
          {
            path: 'before-after/:code/:id',
            component: () => import('@views/pc/lottery/trend/BeforeAfter'),
            props: true
          },
          {
            path: 'combination/:code/:id',
            component: () => import('@views/pc/lottery/trend/Combination'),
            props: true
          },
          {
            path: 'location/:code/:id',
            component: () => import('@views/pc/lottery/trend/Location'),
            props: true
          },
          {
            path: 'following/:code/:id',
            component: () => import('@views/pc/lottery/trend/Following'),
            props: true
          },
          {
            path: 'sum/:code/:id',
            component: () => import('@views/pc/lottery/trend/Sum'),
            props: true
          },
          {
            path: 'pattern/:code/:id',
            component: () => import('@views/pc/lottery/trend/Pattern'),
            props: true
          },
          {
            path: 'decor/:code/:id',
            component: () => import('@views/pc/lottery/trend/Decor'),
            props: true
          },
          {
            path: 'tail/:code/:id',
            component: () => import('@views/pc/lottery/trend/Tail'),
            props: true
          },
          {
            path: 'bose/:code/:id',
            component: () => import('@views/pc/lottery/trend/Bose'),
            props: true
          },
          {
            path: 'zodiac/:type/:code/:id',
            component: () => import('@views/pc/lottery/trend/Zodiac'),
            props: true
          }
        ]
      },
      {
        path: 'recommend',
        name: 'Recommend',
        component: () => import('@views/pc/lottery/Data'),
        props: { category: 'recommend' },
        children: [
          {
            path: 'plan/:code/:id',
            component: () => import('@views/pc/lottery/recommend/Plan'),
            props: true
          },
          {
            path: 'kill/:code/:id',
            component: () => import('@views/pc/lottery/recommend/Kill'),
            props: true
          },
          {
            path: 'formula/:code/:id',
            component: () => import('@views/pc/lottery/recommend/Formula'),
            props: true
          }
        ]
      },
      {
        path: 'industry',
        component: () => import('@views/pc/Industry')
      },
      {
        path: 'articlelist',
        component: () => import('@views/pc/ArticleList')
      },
      {
        path: 'notifications',
        component: () => import('@views/pc/Notifications')
      },
      {
        path: 'rj',
        component: () => import('@views/pc/rj')
      },
      {
        path: 'article/:id',
        component: () => import('@views/pc/Article'),
        props: true
      },
      {
        path: 'cooperation',
        component: () => import('@views/pc/Cooperation')
      },
      {
        path: 'about',
        component: () => import('@views/pc/About')
      },
      {
        path: 'contact',
        component: () => import('@views/pc/Contact')
      },
      {
        path: 'disclaimer',
        component: () => import('@views/pc/Disclaimer')
      },
      {
        path: 'live',
        component: () => import('@views/pc/lottery/live/index'),
        children: [
          {
            path: '168xyft/:id',
            component: () => import('@views/pc/lottery/live/xyft168'),
            // props: { code: '168xyft' }
            props: (route) => ({ code: '168xyft', id: route.params.id })
          },
          {
            path: 'xyft/:id',
            component: () => import('@views/pc/lottery/live/xyft168'),
            // props: { code: '168xyft' }
            props: (route) => ({ code: 'xyft', id: route.params.id })
          },
          {
            path: 'azxy10/:id',
            component: () => import('@views/pc/lottery/live/azxy10'),
            props: (route) => ({ code: 'azxy10', id: route.params.id })
          },
          {
            path: 'pcdd/:id',
            component: () => import('@views/pc/lottery/live/pcdd'),
            props: (route) => ({ code: 'pcdd', id: route.params.id })
          },
          {
            path: 'jisupk10/:id',
            component: () => import('@views/pc/lottery/live/jisupk10'),
            props: (route) => ({ code: 'jisupk10', id: route.params.id })
          },
          {
            path: 'jisukl10/:id',
            component: () => import('@views/pc/lottery/live/jisukl10'),
            props: (route) => ({ code: 'jisukl10', id: route.params.id })
          },
          {
            path: 'jsk3/:id',
            component: () => import('@views/pc/lottery/live/jsk3'),
            props: (route) => ({ code: 'jsk3', id: route.params.id })
          },
          {
            path: 'txffc/:id',
            component: () => import('@views/pc/lottery/live/txffc'),
            props: (route) => ({ code: 'txffc', id: route.params.id })
          },
          {
            path: 'azxy5/:id',
            component: () => import('@views/pc/lottery/live/azxy5'),
            props: (route) => ({ code: 'azxy5', id: route.params.id })
          },
          {
            path: 'azxy8/:id',
            component: () => import('@views/pc/lottery/live/azxy8'),
            props: (route) => ({ code: 'azxy8', id: route.params.id })
          },
          {
            path: 'jisussc/:id',
            component: () => import('@views/pc/lottery/live/jisussc'),
            props: (route) => ({ code: 'jisussc', id: route.params.id })
          },
          {
            path: 'gdklsf/:id',
            component: () => import('@views/pc/lottery/live/gdklsf'),
            props: (route) => ({ code: 'gdklsf', id: route.params.id })
          }

        ]
      },
      {
        path: 'code/:code/:id',
        component: () => import('@views/pc/lottery/Lottery'),
        name: 'Lottery',
        props: true,
        children: [
          {
            path: 'lz/:type',
            component: () => import('@views/pc/lottery/lz/LZNumber'),
            props: true
          },
          {
            path: 'trend/number',
            component: () => import('@views/pc/lottery/trend/Number'),
            props: true
          },
          {
            path: 'trend/size',
            component: () => import('@views/pc/lottery/trend/BigSmall'),
            props: true
          },
          {
            path: 'trend/single-double',
            component: () => import('@views/pc/lottery/trend/SingleDouble'),
            props: true
          },
          {
            path: 'trend/dragon-tiger',
            component: () => import('@views/pc/lottery/trend/DragonTiger'),
            props: true
          },
          {
            path: 'trend/before-after',
            component: () => import('@views/pc/lottery/trend/BeforeAfter'),
            props: true
          },
          {
            path: 'trend/combination',
            component: () => import('@views/pc/lottery/trend/Combination'),
            props: true
          },
          {
            path: 'trend/location',
            component: () => import('@views/pc/lottery/trend/Location'),
            props: true
          },
          {
            path: 'trend/following',
            component: () => import('@views/pc/lottery/trend/Following'),
            props: true
          },
          {
            path: 'trend/sum',
            component: () => import('@views/pc/lottery/trend/Sum'),
            props: true
          },
          {
            path: 'trend/pattern',
            component: () => import('@views/pc/lottery/trend/Pattern'),
            props: true
          },
          {
            path: 'trend/tail',
            component: () => import('@views/pc/lottery/trend/Tail'),
            props: true
          },
          {
            path: 'trend/bose',
            component: () => import('@views/pc/lottery/trend/Bose'),
            props: true
          },
          {
            path: 'trend/zodiac/:type',
            component: () => import('@views/pc/lottery/trend/Zodiac'),
            props: true
          },
          {
            path: 'trend/decor',
            component: () => import('@views/pc/lottery/trend/Decor'),
            props: true
          },
          {
            path: 'data/history',
            component: () => import('@views/pc/lottery/LotteryHistory'),
            props: true
          },
          {
            path: 'data/statistics',
            component: () => import('@views/pc/lottery/LotteryStatistics'),
            props: true
          },
          {
            path: 'data/analyse',
            component: () => import('@views/pc/lottery/LotteryAnalyse'),
            props: true
          },
          {
            path: 'data/omit',
            component: () => import('@views/pc/lottery/LotteryOmit'),
            props: true
          },
          {
            path: 'data/today',
            component: () => import('@views/pc/lottery/LotteryToday'),
            props: true
          },
          {
            path: 'data/tema',
            component: () => import('@views/pc/lottery/LotteryTema'),
            props: true
          },
          {
            path: 'data/long',
            component: () => import('@views/pc/lottery/LotteryLong'),
            props: true
          },
          {
            path: 'data/map',
            component: () => import('@views/pc/lottery/LotteryMap'),
            props: true
          },
          {
            path: 'recommend/plan',
            component: () => import('@views/pc/lottery/recommend/Plan'),
            props: true
          },
          {
            path: 'recommend/kill',
            component: () => import('@views/pc/lottery/recommend/Kill'),
            props: true
          },
          {
            path: 'recommend/formula',
            component: () => import('@views/pc/lottery/recommend/Formula'),
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '/animation',
    name: 'Animation',
    component: () => import('@views/pc/animation/Animation')
  },
  {
    path: '/feiting',
    name: 'Feiting',
    component: () => import('@views/pc/animation/Feiting')
  },
  {
    path: '/saiche',
    name: 'Saiche',
    component: () => import('@views/pc/animation/Saiche')
  },
  {
    path: '/aoZhouLucky',
    name: 'AoZhouLucky',
    component: () => import('@views/pc/animation/AoZhouLucky')
  },
  {
    path: '/gunQiu',
    name: 'gunQiu',
    component: () => import('@views/pc/animation/gunQiu')
  },
  {
    path: '/PCdandan',
    name: 'PCdandan',
    component: () => import('@views/pc/animation/PCdandan')
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
