const kSelectedLotteryKey = 'SelectedLottery'

export default {
  namespaced: true,
  state: {
    selectedLottery: JSON.parse(localStorage.getItem(kSelectedLotteryKey)) || null,
    i_sound: false
  },
  getters: {
    i_sound: state => state.i_sound
  },
  mutations: {
    set_i_sound (state, data) {
      state.i_sound = data
    },
    selectLottery (state, lotteryCode) {
      if (!state.selectedLottery) {
        state.selectedLottery = []
      }
      state.selectedLottery.push(lotteryCode)
      localStorage.setItem(kSelectedLotteryKey, JSON.stringify(state.selectedLottery))
    },
    unselectLottery (state, lotteryCode) {
      if (!state.selectedLottery) return
      const index = state.selectedLottery.findIndex(item => item === lotteryCode)
      if (index === -1) return
      state.selectedLottery.splice(index, 1)
      localStorage.setItem(kSelectedLotteryKey, JSON.stringify(state.selectedLottery))
    }
  },
  actions: {
    set_sound ({ commit }, data) {
      commit('set_i_sound', data)
    }
  }
}
