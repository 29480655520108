import axios from 'axios'
const apiMapConfig = require('./apiMapConfig')
const { getApi } = apiMapConfig

function responseSuccess(response) {
  const data = response.data
  if (data.code === 200) {
    return data
  } else {
    return Promise.reject(data)
  }
}

function responseError(error) {
  let customError
  if (error && error.response) {
    const response = error.response
    customError = {
      code: response.status,
      data: response.data,
      msg: response.data.msg || response.statusText
    }
  } else {
    customError = { code: 500, msg: '网络错误' }
  }
  return Promise.reject(customError)
}

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded'
}

const baseURL = getApi()
console.log(baseURL)

const timeout = 10000
const instance = axios.create({
  baseURL: baseURL,
  timeout,
  headers
})
// instance.interceptors.request.use((config) => {
//   console.log(config)
//   return config
// }, (error) => {
//   return Promise.reject(error)
// })

instance.interceptors.response.use(responseSuccess, responseError)

export default instance
