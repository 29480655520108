<template>
    <div>
        <!-- <el-popover
        placement="top-start"
        width="520"
        trigger="click">
            <div class="cont">{{cont}}</div>
            <div class="intro" slot="reference">
                <span>!</span>
                图表说明
            </div>
        </el-popover> -->
    </div>
</template>

<script>
import { popover } from 'element-ui'
export default {
    props: {
        cont: {
            default: ''
        }
    },
    components: {
        [popover.name]: popover
    }
}
</script>

<style lang="scss" scoped>
    .cont {
        font-size: 14px;
        line-height: 24px;
        color: #959595;
    }
    .intro {
        padding-left: 24px;
        white-space: nowrap;
        font-size: 14px;
        color: #959595;
        cursor: pointer;
        display: flex;
        align-items: center;
        >span {
            margin-right: 10px;
            font-weight: 400;
            color: #535353;
            font-size: 12px;
            width: 15px;
            height: 15px;
            line-height: 15px;
            text-align: center;
            background: #E6E6E6;
            border-radius: 50%;
        }
    }
</style>