
import Vue from 'vue'
import { Message } from 'element-ui'

function success (message) {
  Message({
    showClose: true,
    message: message,
    type: 'success'
  })
}

function warning (message) {
  Message({
    showClose: true,
    message: message,
    type: 'warning'
  })
}

function info (message) {
  Message({
    showClose: true,
    message: message,
    type: 'info'
  })
}

function error (error) {
  Message({
    showClose: true,
    message: error.msg || error,
    type: 'error'
  })
}

const MessagePlugin = {
  install: (Vue, options) => {
    Vue.prototype.$message = {
      success,
      warning,
      info,
      error
    }
  }
}

Vue.use(MessagePlugin)

export default MessagePlugin
