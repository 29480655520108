import Vue from 'vue'
import Vuex from 'vuex'
import lottery from './lottery'
import home from './home'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    lottery,
    home
  }
})
