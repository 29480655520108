<template>
  <div>
    <div v-if="type == 0" class="select_box">
      <span>选择期数</span>
      <el-select v-model="value" size="small" @change="change">
        <el-option v-for="item in data1"
          :key="item.value"
          :label="item.label"
          :value="item.value"></el-option>
      </el-select>
    </div>
    <div v-if="type == 1" class="select_box">
      <span>选择期数</span>
      <el-select v-model="value" size="small" @change="change">
        <el-option v-for="item in data2"
          :key="item.value"
          :label="item.label"
          :value="item.value"></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'

export default {
  name: 'select-user',
  props: {
    page: {
      default: null
    },
    type: {
      default: 0
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  watch: {
    '$route.path': {
      handler () {
        if (this.type == 0) {
          this.value = /\d+/.test(this.page) ? this.page : 50
        }
        if (this.type == 1) {
          this.value = /\d+/.test(this.page) ? this.page : 10
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      value: null,
      data1: [
        {
          label: '最近50期',
          value: 50
        },
        {
          label: '最近100期',
          value: 100
        },
        {
          label: '最近200期',
          value: 200
        },
        {
          label: '最近500期',
          value: 500
        },
        {
          label: '最近1000期',
          value: 1000
        }
      ],
      data2: [
        {
          label: '最近10期',
          value: 10
        },
        {
          label: '最近20期',
          value: 20
        },
        {
          label: '最近30期',
          value: 30
        },
        {
          label: '最近50期',
          value: 50
        },
        {
          label: '最近100期',
          value: 100
        }
      ]
    }
  },
  methods: {
    change (value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .select_box {
    display: flex;
    align-items: center;
    >span {
      margin-right: 15px;
      font-size: 14px;
      color: #222;
    }
  }
</style>
