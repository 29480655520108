import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from '../../api'

import 'babel-polyfill'

// sass
import './scss/app.scss'

// Plugins
import '../../plugins/loading'
import '../../plugins/message'
import '@/src/filter'

import '../../directive/hidden-entrance'

import { Row, Col, MessageBox } from 'element-ui'
Vue.use(Row)
Vue.use(Col)
Vue.prototype.$alert = MessageBox.alert

import introDefault from '@components/pc/intro-default'
Vue.component('introDefault', introDefault)
import selectUser from '@components/pc/select-user'
Vue.component('selectUser', selectUser)

Vue.config.productionTip = false

import { fn, get_type_name } from '@config/intro'

Vue.prototype.intro = fn
Vue.prototype.get_type_name = get_type_name

Vue.use(Api)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
