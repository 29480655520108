/// 全局过滤器
import Vue from 'vue'
import moment from 'moment'
Vue.filter('formatTimeInterval', function (interval, format) {
  const date = moment(interval * 1000)
  if (format) {
    return date.format(format)
  } else {
    return date.format('YYYY-MM-DD HH:mm:ss')
  }
})

Vue.filter('formatDate', function (value, format) {
  const date = moment(value)
  if (format) {
    return date.format(format)
  } else {
    return date.format('YYYY-MM-DD HH:mm:ss')
  }
})
