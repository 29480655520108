const apiMapConfig = [
  {
    hostname: "www.nnzx888.net",
    api: "https://api.nnzx888.net/",
  },
  {
    hostname: "www.nnzx88.net",
    api: "https://api.nnzx888.net/",
  },
  {
    hostname: "www.nnzx8.net",
    api: "https://api.nnzx888.net/",
  },
  {
    hostname: "35.247.167.228",
    api: "http://35.247.167.228:29857/",
  },
  {
    hostname: "34.92.113.161",
    api: "http://34.92.113.161:29857/",
  }
];

const DEV_API = "https://api.nnzx888.net/"
const VUE_APP_PROXY = '/proxy'

const getApi = () => {
  const visitHostname = window.location.hostname;
  if (process.env.NODE_ENV === 'production') {
    let api = "";
    for (let item of apiMapConfig) {
      if (item.hostname === visitHostname) {
        api = item.api;
        break;
      }
    }
    if (!api) return apiMapConfig[0]['api'];
    return api;
  } else {
    return VUE_APP_PROXY;
  }
}

module.exports = {
  DEV_API: DEV_API,
  VUE_APP_PROXY: VUE_APP_PROXY,
  getApi: getApi
}
