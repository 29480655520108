import Vue from 'vue'
import hideCodes from '@/src/config/no-live-code'
const directive = {
  install: (Vue, options) => {
    Vue.directive('hidden-entrance', {
      inserted: function (el, binding, vnode) {
        const isHidden = hideCodes.some(item => {
          if (typeof item === 'string') {
            return item === binding.value.code
          } else {
            const id = parseInt(binding.value.id)
            return item.code === binding.value.code && item.id === id
          }
        })
        if (isHidden) {
          el.style.display = 'none'
        } else {
          el.style.display = null
        }
      },
      update: function (el, binding, vnode) {
        if (binding.value === binding.oldValue) return
        const isHidden = hideCodes.some(item => {
          if (typeof item === 'string') {
            return item === binding.value.code
          } else {
            const id = parseInt(binding.value.id)
            return item.code === binding.value.code && item.id === id
          }
        })
        if (isHidden) {
          el.style.display = 'none'
        } else {
          el.style.display = null
        }
      }
    })
  }
}

Vue.use(directive)
